div[class^='Nav__navBar'] {
  display: none;
}

body {
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

div {
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

.p-datatable-tbody {
  font-size: 0.8em;
}

.card {
  padding: 16px;
}

ul.layout-menu.layout-main-menu.clearfix li a i.fa {
  font-size: 2.7em;
}

.alternateCard {
  background-image: linear-gradient(to left, rgba(69, 127, 202, 0.55), rgba(69, 127, 202, 0.65));
}

.alternateCardHeader {
  background-image: linear-gradient(to right, rgba(33, 37, 41, 0.95), rgba(69, 127, 202, 0.65));
  color: white !important;
}

div.p-panel.alternateCard div.p-panel-titlebar {
  background-image: linear-gradient(to right, rgba(33, 37, 41, 0.95), rgba(69, 127, 202, 0.65));
  color: white !important;
}

.ui-panel-title {
  line-height: 1.5em;
  margin: 0;
  font-size: 1.143em;
}

.ui-panel-titlebar i {
  margin-right: 5px;
}

label.textInputLabel {
  font-weight: bold;
  margin-right: 10px;
}

input.inputTextControl {
  margin-right: 20px;
}

div.newMsgDialog div.ui-dialog-content.ui-widget-content {
  overflow: visible;
}

.newMsgDialog {
  width: 600px;
  height: 400px;
}

ul.messagesMenu.layout-menu.fadeInDown {
  width: 320px;
}

span.versionNumber {
  color: white;
  text-align: right;
  font-size: 10px;
  float: right;
  padding-right: 20px;
  padding-bottom: 10px;
}

li.subMenuItem {
  font-size: 9px;
}
tr.ui-datatable-even.ui-state-highlight > td > span > a,
tr.ui-datatable-odd.ui-state-highlight > td > span > a {
  color: #6bb2ff;
  font-weight: bold;
}
span.mainNavMessageMenuText {
}

span.LoadableComponent {
  text-align: center;
  width: 100%;
}

body .p-datatable .p-sortable-column.p-highlight {
  background-color: #8ab1db27;
  color: black;
  border-top-color: #8ab1db27;
}

body .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: black;
}

ul.NoBullets {
  list-style: none;
}

ul.NoBullets > li {
  padding-top: 4px;
}

body p-sortable-column p-highlight {
  background-color: red !important;
}

li.PaddedListItem {
  margin-top: 14px !important;
}
