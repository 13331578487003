ul.reportList {
  list-style: none;
  font-size: 16px;
}

li.reportListItem {
  line-height: 1.4;
  margin-bottom: 2px;
  padding: 2px;
}
